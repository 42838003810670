import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import _ from "lodash";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initializeI18next } from "../methods";
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;

initializeI18next();

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    saveMissing: false,
    initImmediate: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true,
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((lng, ns) =>
          import(`../../public/locales/${lng}/${ns}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: `${REACT_APP_PG3_APP_DOMAIN}/language_text?lang={{lng}}`,
          crossDomain: true,
        },
        {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      ],
    },
  });

export default i18n;
