import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_DOB_BANNER_STATE } from "../store/actions/auth";
import { logoutAction } from "../methods/auth_methods";
import { editPatient } from "../store/actions/patientList";
import { clearCookie } from "../methods";
import toastr from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import { years, months } from "../methods/constant";
import _ from "lodash";
import { updateDOBAndGender } from "../store/actions/patientWelcome";
import PgSelect from "./PgSelect";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'react-datepicker/dist/react-datepicker.css'

export default function DOBInputModal({ showModal, onCancel }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const patientId = useSelector((state) => state.auth.currentUserId);
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const userGender = useSelector((state) => state.auth.gender);
  const userDOB = useSelector((state) => state.auth.dob);
  const userSkinColor = useSelector((state) => state.auth.skin_color);
  const updateDOBAndGenderFn = (pld) =>
    dispatch(
      updateDOBAndGender(token, currentPracticeID, currentPractice, pld)
    );

  const [dob, setDOB] = useState(null);
  const [dobError, setDobError] = useState("");
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [skinColor, setSkinColor] = useState("");
  const [skinColorError, setSkinColorError] = useState("");

  const __updateDOBAndGender = async () => {
    setDobError("");
    setGenderError("");
    setSkinColorError("");
    if (!userDOB && !_.isDate(dob)) {
      setDobError(t("alert_dob_error"));
      return;
    }
    if (!userGender && gender.length === 0) {
      setGenderError(t("alert_field_required"));
      return;
    }
    if (!userSkinColor && skinColor.length === 0) {
      setSkinColorError(t("alert_field_required"));
      return;
    }
    let data = {
      id: patientId,
      dob: moment(dob).format() || userDOB,
      gender: gender || userGender,
      skin_color: skinColor || userSkinColor,
    };
    const response = await updateDOBAndGenderFn(data);
    if (!response.error) {
      onCancel(false);
      toastr.success(t("alert_pat_success"));
      dispatch({ type: TOGGLE_DOB_BANNER_STATE });
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="border-radius "
      backdrop="static"
      className="edit-patient-modal"
      align="center"
      centered
    >
      <Modal.Body>
        <div className="banner-text d-lg-block">
          <h5 className="text-start fw-bold my-4">{t("popup_text_1")}</h5>
          <div>
            {!userDOB && (
              <div className="my-2 dateVal">
                <ReactDatePicker
                  renderCustomHeader={({ date, changeYear, changeMonth }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  onChange={(date) => {
                    setDOB(date);
                  }}
                  style={{ width: "100%" }}
                  value={dob}
                  dateFormat="MM/dd/yyyy"
                  onChangeRaw={() => {
                    setDOB("");
                  }}
                  selected={dob}
                  className={_.isEmpty(dobError) ? "dateFld" : "dateFld error"}
                  placeholderText={t("pracand_dob_ph")}
                  minDate={new Date("1950-01-01")}
                  maxDate={new Date()}
                  popperPlacement="bottom-start"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
                <div
                  className="text-start text-danger mx-2 my-1 transition-all"
                  style={
                    !_.isEmpty(dobError)
                      ? { opacity: 1 }
                      : { opacity: 0, minHeight: "16px" }
                  }
                >
                  {!_.isEmpty(dobError) && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          `<i class="fas fa-info-circle me-2"></i>` + dobError,
                      }}
                    ></div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {!userGender && (
              <div className="my-2">
                <PgSelect
                  label={t("pracand_gender")}
                  showLabel={false}
                  required={true}
                  optional={false}
                  practiceAddPatient={true}
                  id="gender__addpatient"
                  setSelectedItem={setGender}
                  selectedItem={gender}
                  placeholder={t("pat_gender")}
                  options={[t("male"), t("female"), t("intersex")]}
                  error={gender !== "" ? "" : genderError}
                  errorTextStart="text-start"
                  errorLineHeight={{ lineHeight: "16px" }}
                />
              </div>
            )}
          </div>
          <div>
            {!userSkinColor && (
              <div className="my-2">
                <PgSelect
                  label={t("pat_self_skin")}
                  showLabel={false}
                  required={true}
                  optional={false}
                  practiceAddPatient={true}
                  id="skincolor__addpatient"
                  setSelectedItem={setSkinColor}
                  selectedItem={skinColor}
                  placeholder={t("pat_self_skin_full")}
                  options={[t("dark"), t("medium"), t("light")]}
                  error={skinColor !== "" ? "" : skinColorError}
                  errorTextStart="text-start"
                  errorLineHeight={{ lineHeight: "16px" }}
                />
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-primary__outline agree_btn_style mx-md-3 mx-1"
              onClick={logoutAction}
            >
              {t("patient_logout")}
            </button>
            <button
              type="button"
              class="btn btn-primary agree_btn_style mx-md-3 mx-1"
              onClick={__updateDOBAndGender}
            >
              {t("update")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
